<template>
  <div>
    <WarningDialog
      ref="WarningDialogRef"
      :loading="deleting"
      @action="deleteItem"
    />
    <p class="caption pasive--text text-right">
      {{ totalitems }} {{ totalitems == 1 ? "resultado" : "resultados" }}
    </p>
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      loading-text="Cargando..."
      :no-data-text="'Sin registros'"
      item-key="id"
      item-class="text-center"
      disable-sort
      :footer-props="{
        itemsPerPageText: 'Resultados por pág.',
        itemsPerPageAllText: 'Todos',
        pageText: '',
        showCurrentPage: true,
        itemsPerPageOptions: [15, 30, 60],
      }"
      :options.sync="options"
      :server-items-length="totalitems"
    >
      <template slot="item.date" scope="props">
        {{ formatDate(props.item.date) }}
      </template>
      <template slot="item.patient" scope="props">
        <Patient
          v-if="props.item.invoice && props.item.invoice.patient"
          :patient="props.item.invoice.patient"
        >
          {{ props.item.invoice.patient.firstName }}
          {{ props.item.invoice.patient.lastName }}
        </Patient>
      </template>
      <template slot="item.paymentmethod" scope="props">
        <v-tooltip v-if="paymentMethod(props.item).text == 'Mixto'" bottom>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              {{ paymentMethod(props.item).text }}
            </div>
          </template>
          <div class="caption">
            Efectivo: {{ paymentMethod(props.item).cash }}
          </div>
          <div class="caption">
            Tarjeta: {{ paymentMethod(props.item).creditCard }}
          </div>
          <div class="caption">
            Transferencia: {{ paymentMethod(props.item).bank }}
          </div>
        </v-tooltip>
        <span v-else>{{ paymentMethod(props.item).text }}</span>
      </template>
      <template slot="item.total" scope="props">
        <span class="font-weight-bold">{{
          formatMoney(props.item.total)
        }}</span>
      </template>
      <template slot="item.createdBy" scope="props">
        <span v-if="props.item.createdBy"
          >{{ props.item.createdBy.firstName }}
          {{ props.item.createdBy.lastName }}
        </span>
      </template>
      <template slot="item.action" scope="props">
        <div class="d-flex justify-center">
          <v-menu bottom offset-x>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" class="mr-3" text icon>
                <v-icon size="20px">mdi-dots-vertical </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="showWarning(props.item)">
                <v-list-item-content class="font-weight-medium">
                  <div class="d-flex align-center">
                    <v-icon size="18px" class="mr-3"> mdi-delete </v-icon>
                    <div class="subtitle-2 font-weight-regular">Eliminar</div>
                  </div>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import { formatCurrency, formatFloat } from "../../helpers/formatters";
import Patient from "../Patient/Patient.vue";
import WarningDialog from "../WarningDialog.vue";
import { mapMutations } from "vuex/dist/vuex.common.js";

export default {
  name: "InvoicePaymentList",
  components: {
    Patient,
    WarningDialog,
  },
  data() {
    return {
      headers: [
        {
          text: "Fecha",
          value: "date",
        },
        { text: "Paciente", value: "patient" },
        { text: "Método de pago", value: "paymentmethod" },
        { text: "Importe", value: "total" },
        { text: "Realizado por", value: "createdBy" },
        { text: "", value: "action" },
      ],
      loading: false,
      options: {},
      totalitems: 0,
      item: null,
      items: [],
      deleting: false,

      // filters
      dateFrom: "",
      dateTo: "",
      terms: "",
      patientId: "",
      page: 1,
      pageSize: 15,
    };
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  methods: {
    ...mapActions(["fetchInvoicePayments"]),
    ...mapMutations(["setAlert"]),
    paymentMethod(invoicePayment) {
      const cash = formatFloat(invoicePayment.cash);
      const creditCard = formatFloat(invoicePayment.creditCard);
      const bank = formatFloat(invoicePayment.bank);

      const paymentMethod = {
        cash: formatCurrency(cash),
        creditCard: formatCurrency(creditCard),
        bank: formatCurrency(bank),
      };

      const valuesExist = [cash !== 0, creditCard !== 0, bank !== 0];
      const count = valuesExist.filter(Boolean).length;
      if (count >= 2) {
        return {
          ...paymentMethod,
          text: "Mixto",
        };
      }

      if (cash) {
        return {
          ...paymentMethod,
          text: "Efectivo",
        };
      }

      if (creditCard) {
        return {
          ...paymentMethod,
          text: "Tarjeta",
        };
      }

      if (bank) {
        return {
          ...paymentMethod,
          text: "Transferencia bancaria",
        };
      }
    },
    formatMoney(x) {
      return formatCurrency(x);
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    formatDateAndTime(date) {
      return moment(date).format("DD/MM/YYYY hh:mm A");
    },
    handleClickEdit(item) {},
    showWarning(item) {
      this.item = item;
      this.$refs.WarningDialogRef.description = `¿Seguro que desea eliminar el registro?`;
      this.$refs.WarningDialogRef.show = true;
    },
    async deleteItem() {
      try {
        this.deleting = true;
        const res = await axios.delete(`/api/invoice_payment/${this.item.id}`, {
          data: {
            doctorId: this.currentUser.doctor.id,
          },
        });
        const { message } = res.data;
        this.setAlert({
          show: true,
          color: "success",
          icon: "mdi-check-circle",
          timeout: 4000,
          text: message,
        });
        this.items = this.items.filter((i) => i.id !== this.item.id);
        this.$emit("item-deleted");
      } catch (error) {
        console.log(error);
        this.setAlert({
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: error?.response?.data?.message || "Error inesperado",
        });
      } finally {
        this.deleting = false;
        this.$refs.WarningDialogRef.show = false;
      }
    },
    handleItemCreated({ invoice }) {
      this.items.unshift(invoice);
    },
    handleItemUpdated({ invoice }) {
      this.items = this.items.map((i) => (i.id === invoice.id ? invoice : i));
    },
    handleItemDeleted({ invoice }) {
      this.items = this.items.filter((i) => i.id !== invoice.id);
    },
    setFiltersAndGetData({ dateFrom, dateTo, term, patientId, locationId }) {
      this.dateFrom = dateFrom;
      this.dateTo = dateTo;
      this.term = term;
      this.patientId = patientId;
      this.locationId = locationId;
      this.getData();
    },
    async getData() {
      this.loading = true;

      const page = this.page || 1;
      const pageSize = this.pageSize || 15;

      const data = await this.fetchInvoicePayments({
        page,
        pageSize,
        doctorId: this.currentUser.doctor.id,
        patientId: this.patientId,
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        term: this.term,
        locationId: this.locationId,
        orderBy: [
          {
            column: "invoice_payment.date",
            direction: "DESC",
          },
          {
            column: "invoice_payment.updatedAt",
            direction: "DESC",
          },
        ],
      });

      if (data) {
        const { items, count } = data;
        this.items = items || [];
        this.totalitems = count || 0;
      }

      window.scrollTo(0, 0);
      this.loading = false;
    },
  },
  watch: {
    options: {
      handler(value) {
        if (value) {
          this.page = value.page || 1;
          this.pageSize = value.itemsPerPage || 15;
        }
      },
      deep: true,
    },
  },
};
</script>
